import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import { addEventListeners } from '@/lib/util'

Vue.component('v-video', {
  props: {
    sources: {
      type: [String, Array],
      default: () => [],
    },
    videoSubtitles: {
      type: String,
      default: '',
    },
    sourceBreakpoints: {
      type: Array,
      default: () => {
        return [639, 1023, 1439] // Aligns with css breakpoints
      },
    },
    poster: {
      type: String,
      default: '',
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    showDefaultControls: {
      type: Boolean,
      default: false,
    },
    showVolumeButton: {
      type: Boolean,
      default: false,
    },
    threshold: {
      type: Number,
      default: 0,
    },
    thresholdGroup: {
      type: String,
      default: 'default',
    },
    loader: {
      type: Boolean,
      default: false,
    },
    fadeIn: {
      type: Boolean,
      default: true,
    },
    videoWidth: {
      type: Boolean,
      default: false,
    },
    defaultShowSubtitle: {
      type: Boolean,
      default: false,
    },
    defaultSubtitleLang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      isLoaded: false,
      isSuspended: false,
      isPlaying: false,
      isUnmuting: true,
      videoEl: null,
      winWidth: 1440,
      isShowSubtitle: false,
      showSubtitleMenu: false,
      subtitleLang: this.defaultSubtitleLang,
      source: null
    }
  },
  computed: {
    controlButton() {
      return this.$refs.controlButton && this.$refs.controlButton.length
        ? this.$refs.controlButton[0]
        : this.$refs.controlButton
    },
    sourcesArr() {
      return Array.isArray(this.sources) ? this.sources : [this.sources]
    },
    sourcesArrFiltered() {
      // Filter out undefined or empty sources
      return this.sourcesArr.filter((source) => source && source.length)
    },
    numOfBreakpoints() {
      return this.sourceBreakpoints.length + 1
    },
    breakpointIndex() {
      const breakpoint = this.sourceBreakpoints.find((breakpoint) => {
        return this.winWidth <= breakpoint
      })

      // eslint-disable-next-line unicorn/prefer-includes
      return this.sourceBreakpoints.indexOf(breakpoint) >= 0
        ? this.sourceBreakpoints.indexOf(breakpoint)
        : this.sourceBreakpoints.length // Represents the default breakpoint
    },
    currentSource() {
      // Limits breakpointIndex by length of sourcesArrFiltered
      const index =
        this.breakpointIndex <= this.sourcesArrFiltered.length - 1
          ? this.breakpointIndex
          : this.sourcesArrFiltered.length - 1

      return this.sourcesArrFiltered[index]
    },
  },
  watch: {
    isLoaded(val) {
      if (val) {
        this.isShowSubtitle = this.defaultShowSubtitle

        if (this.isShowSubtitle) {
          this.changeSubtitleLang(this.subtitleLang)
        }

        this.$nextTick(() => {
          this.$emit('loaded')
        })
      }
    },
  },
  mounted() {
    this.setWinWidth()

    this.videoEl = this.$refs.videoEl && this.$refs.videoEl.length
      ? this.$refs.videoEl[0]
      : this.$refs.videoEl

    this.source = this.$refs.source && this.$refs.source.length
      ? this.$refs.source[0]
      : this.$refs.source

    if (!window.videoObserver || !window.videoObserver[this.thresholdGroup])
      this.initVideoObserver()

    this.observeNewVideo(this.videoEl, this.thresholdGroup)

    window.addEventListener('resize', () => {
      this.setWinWidth()

      this.$nextTick(() => {
        if (this.source?.hasAttribute('data-src')) {
          this.loadVideo(this.videoEl)
        }
      })
    })

    // * Force to play video automatically when user interact with our website in a low power mode mobile
    addEventListeners(document.body, 'click touchstart', () => {
      this.playIfSuspend()
    })

    this.turnOffSubtitles()
  },
  methods: {
    toggleSubtitleMenu() {
      this.showSubtitleMenu = !this.showSubtitleMenu
    },
    changeSubtitleLang(lang) {
      this.isShowSubtitle = !isEmpty(lang)
      this.subtitleLang = lang

      for (let i = 0; i < this.videoEl.textTracks.length; i++) {
        if (this.videoEl.textTracks[i].language === lang) {
          this.videoEl.textTracks[i].mode = 'showing'
        } else {
          this.videoEl.textTracks[i].mode = 'hidden'
        }
      }

      this.showSubtitleMenu = false
    },
    turnOffSubtitles() {
      for (let i = 0; i < this.videoEl.textTracks.length; i++) {
        this.videoEl.textTracks[i].mode = 'hidden'
      }
    },
    setWinWidth() {
      this.winWidth = document.documentElement.clientWidth
    },
    initVideoObserver() {
      const config = {
        threshold: this.threshold,
      }

      if (!window.videoObserver) {
        window.videoObserver = {}
      }

      window.videoObserver[this.thresholdGroup] = new IntersectionObserver(
        (entries, self) => {
          entries.forEach((entry) => {
            // We must use the right Video vue instance of the entry target element
            const _super = entry.target.parentNode.__vue__
            if (!_super) return

            if (entry.isIntersecting) {
              if (entry.target.readyState === 0) {
                // Video element has no data

                const source = _super.$refs.source && _super.$refs.source.length ? _super.$refs.source[0] : _super.$refs.source
                if (source?.hasAttribute('data-src')) {
                  _super.loadVideo(entry.target)
                }
              } else if (entry.target.readyState >= 2) {
                // Data is available for the current playback position

                if (_super.autoplay) _super.playVideo()
              }
            } else if (entry.target.readyState >= 2) {
              if (_super.autoplay) _super.pauseVideo()
            }
          })
        },
        config
      )
    },
    observeNewVideo() {
      window.videoObserver[this.thresholdGroup].observe(this.videoEl)
    },
    loadVideo(video) {
      // Sync this.videoEl to video from IntersectionObserverEntry
      this.videoEl = video

      this.source.src = this.source.dataset.src

      if (!isEmpty(this.source.src))
        this.source?.removeAttribute('data-src')

      this.videoEl.load()

      // Video canPlay event sets this.isLoaded = true
      // Video play event sets this.isPlaying = true
    },
    playVideo() {
      this.videoEl.play()
      // Video play event sets this.isPlaying = true
    },
    pauseVideo() {
      this.videoEl.pause()
      // Video pause event sets this.isPlaying = false
    },
    controlButtonClickHandler() {
      if (this.videoEl.paused) {
        this.playVideo()
      } else {
        this.pauseVideo()
      }
    },
    volumeButtonClickHandler() {
      if (this.videoEl) {
        this.videoEl.muted = !this.isUnmuting
      }

      this.isUnmuting = !this.isUnmuting
    },
    playIfSuspend() {
      if (this.autoplay && this.isSuspended) {
        this.playVideo()
        this.isSuspended = false

        let checkPlayingInterval = null
        checkPlayingInterval = setInterval(() => {
          if (checkPlayingInterval) clearInterval(checkPlayingInterval)
          if (!this.isPlaying) {
            this.isSuspended = true
          }
        }, 500)
      }
    },
  },
})